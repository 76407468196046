import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import PageLayout from '@app/components/Layouts/PageLayout';

interface Props extends RouteProps {
	component: any;
}

const PageRoute: React.FC<Props> = ({
	component: Component,
	children,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				<PageLayout>
					<Component {...props} />
				</PageLayout>
			)}
		/>
	);
};

export default PageRoute;

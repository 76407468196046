import { DaysOfWeekNames } from '@app/objects/DaysOfWeek';
import { WorkingHours } from '@app/objects/Location';

const getDays = (days: Array<number>): string => {
	if (!days.length) {
		return 'No working days';
	}

	if (days.length === 7) {
		return 'Every day';
	}

	const schedule: Array<string> = [];
	let startDay: number = days[0];
	let endDay: number = days[0];

	for (let i = 0; i < days.length; i++) {
		if (days[i + 1] - days[i] === 1) {
			endDay = days[i + 1];
		} else {
			if (startDay === endDay) {
				schedule.push(DaysOfWeekNames[startDay]);
			} else {
				schedule.push(`${DaysOfWeekNames[startDay]} - ${DaysOfWeekNames[endDay]}`);
			}
			startDay = endDay = days[i + 1];
		}
	}

	return schedule.join(', ');
};

export const getWorkingDays = (workingHours: Array<WorkingHours>): string => {
	const workingDays = new Set<number>();
	workingHours.forEach((el) => workingDays.add(el.dayOfWeek));
	const sortedDays: Array<number> = Array.from(workingDays).sort((a, b) => a - b);
	return getDays(sortedDays);
};

import * as React from 'react';
import { Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';
import { loadableDelay, params } from '@common/react/loadable/loadableSettings';

import Layout from '@app/components/Layout';
import MainRoute from '@app/components/Routes/MainRoute';
import PageRoute from '@app/components/Routes/PageRoute';

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HomePage" */
		'@app/components/Pages/Home/Home'
	)), params);
const Blog = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Blog" */
		'@app/components/Pages/Blog/Blog'
	)), params);
const Faqs = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Faq" */
		'@app/components/Pages/Faqs/Faqs'
	)), params);

export const routes = (<Layout>
	<Switch>
		<MainRoute exact path="/" component={Home} />
		<PageRoute path="/blog" component={Blog} />
		<PageRoute exact path="/faq" component={Faqs} />
		<NotFoundRoute path="*" openRoute={(props) => <PageRoute {...props} />} />
	</Switch>
</Layout>);

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ApplicationState } from '@app/store';

const Background: React.FC<{count: number}> = ({ children, count }) => {
	const siteSettings = useSelector((state: ApplicationState) => state.siteViewSettings.item, shallowEqual);
	const colorSetGradientEnabled = siteSettings?.colorSetGradientEnabled ?? false;
	const withBlur = colorSetGradientEnabled ? 'with-blur' : 'without-blur';

	const getCircles = (count: number): React.ReactNode => {
		return <>{[...Array(count)].map((el, index) => <span className="bg-circle" key={index} />)}</>;
	};

	return <>
		{colorSetGradientEnabled ? getCircles(count) : null}
		<div className={`background ${withBlur}`}>
			{children}
		</div>
	</>;
};

export default Background;

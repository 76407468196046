import { WithDeleted } from '@common/typescript/objects/WithDeleted';

export interface ColorSet extends WithDeleted {
	baseColor1: string;
	baseColor2: string;
	baseColor3: string;
	baseColor4: string;
	baseColor5: string;
	baseColor6: string;
	gradientColor1: string;
	gradientColor2: string;
}

export const defaultColorSet: ColorSet = {
	id: 0,
	baseColor1: '#fbf0e4',
	baseColor2: '#fcf4f2',
	baseColor3: '#f8e3e5',
	baseColor4: '#a58886',
	baseColor5: '#ff6f80',
	baseColor6: '#fff',
	gradientColor1: '#f0d4ab',
	gradientColor2: '#ff9696',
};

import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import once from 'lodash/once';
import { fetch } from 'domain-task';
import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import { BaseApplicationState } from '@common/react/store';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { TypeKeys } from '@common/react/store/Login';

const params = { fallback: <Loader /> };

const ErrorPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ErrorPage" */ '@common/react/components/Pages/ErrorPage/ErrorPage')), params);

const AccessDenied = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AccessDenied" */
		'@common/react/components/Pages/AccessDenied/AccessDenied'
	)), params);

const NotFound = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageNotFound" */
		'@common/react/components/UI/PageNotFound/PageNotFound'
	)), params);

export type RequestType = <T, >(type: string, data?: BaseParams, beforeRequest?: () => void, ttl?: number, signal?: AbortSignal) => Promise<T>;

interface ErrorComponents {
	accessDenied: React.JSXElementConstructor<object>;
	notFound: React.JSXElementConstructor<React.PropsWithChildren<{message?: string}>>;
	errorPage: React.JSXElementConstructor<React.PropsWithChildren<{message?: string}>>;
}

interface ErrorComponentsOptions {
	notFountMessage?: string;
}

export interface RequestProviderProps {
	/**
	 * cache available flag, by default is true
	 */
	cache?: boolean;
	/**
	 * time to live (ms) for cached response if cache is available
	 */
	ttl?: number;
	errorHandlerForCustomCodes?: (ResponseError: ResponseError) => void;
	getErrorComponents?: (ResponseError: ResponseError, component: ErrorComponents, options: ErrorComponentsOptions) => React.ReactNode;
	errorComponents?: Partial<ErrorComponents>;
	/**
	 * debug flag - if true, output cache state on each updateCache and leave keys at cache after delete value
	 * by default false
	 */
	debug?: boolean;
	/**
	 * message for not found page
	 */
	notFoundPageMessage?: string;
}

export interface Cache {
	[key: string]: any;
}

export interface RequestProviderContextState {
	request: RequestType;
	notFountMessage?: string;
}

export interface RequestProviderContextActions {
	updateCache: (type, data, response, ttl?: number) => void;
	getFromCache: (type, params) => any;
}

export interface RequestProviderContext {
	state: RequestProviderContextState;
	actions: RequestProviderContextActions;
}

export const createRequestProviderContext = once(() => React.createContext({} as RequestProviderContext));

export const useRequestProviderContext: () => RequestProviderContext = () => React.useContext(createRequestProviderContext());

interface Message<T> {
	success: number;
	response: T;
	session: string;
}

export enum ErrorCode
{
	NotStated = 0,
	NoRights = 1,
	UnspecifiedError = 42,
	NotFound = 65,
	CaptchaRequired = 66,
	TemporaryDisabled = 67
}

export interface ResponseError {
	message: string;
	code: number;
	path: string;
	isLogin?: boolean;
}

const defaultErrorComponents = {
	accessDenied: AccessDenied,
	notFound: NotFound,
	errorPage: ErrorPage,
};

export const getDefaultErrorComponents = (error: ResponseError, components: ErrorComponents, options?: ErrorComponentsOptions) => {
	const {
		accessDenied: AccessDeniedComponent,
		notFound: NotFoundComponent,
		errorPage: ErrorPageComponent,
	} = components;
	switch (error.code) {
		case ErrorCode.NoRights:
			return <AccessDeniedComponent />;
		case ErrorCode.NotFound:
			return <NotFoundComponent message={options?.notFountMessage} />;
		case ErrorCode.UnspecifiedError:
			return <ErrorPageComponent message={error.message} />;
		default:
			return null;
	}
};

export const RequestProvider: React.FC<RequestProviderProps> = ({
	children,
	cache: cacheProps = true,
	ttl: defaultTtl = 0,
	getErrorComponents = getDefaultErrorComponents,
	errorHandlerForCustomCodes,
	errorComponents = defaultErrorComponents,
	debug = false,
	notFoundPageMessage,
}) => {
	const [errorComponent, setErrorComponent] = React.useState<any>(null);
	const [cache, setCache] = React.useState<Cache>({});
	const timers = React.useRef<any>({});

	const ItemContext = createRequestProviderContext();

	const session = useSelector((state: BaseApplicationState<BaseUser>) => state.login.session, shallowEqual);
	const history = useHistory();
	const dispatch = useDispatch();
	const context = useRequestProviderContext();
	const notFountMessage = notFoundPageMessage || context?.state?.notFountMessage;

	const updateCache = (type, params, response, ttl = defaultTtl) => {
		debug && console.log(cache);

		if (cacheProps && ttl && ttl > 0) {
			const key = `${type}${JSON.stringify(params)}`;

			setCache((prev) => {
				return { ...prev, [key]: response };
			});

			if (timers.current[key]) {
				clearTimeout(timers.current[key]);
			}
			timers.current[key] = setTimeout(() => {
				if (timers.current[key]) {
					setCache((prev) => {
						const cache = { ...prev, [key]: undefined };
						!debug && delete cache[key];
						return cache;
					});
				}
			}, ttl);
		}
	};

	const getFromCache = (type, params) => {
		if (cacheProps) {
			const key = `${type}${JSON.stringify(params)}`;

			if (cache[key]) {
				return cache[key];
			}
		}
	};

	React.useEffect(() => {
		if (cacheProps) {
			return () => {
				Object.values(timers.current)
					.map((timer: any) => timer && clearTimeout(timer));
			};
		}
	}, []);

	const errorHandler = (error: ResponseError) => {
		if (error.code === ErrorCode.NotStated) {
			return;
		}

		if (error.code === ErrorCode.NoRights) {
			if (!error.isLogin) {
				dispatch({ type: TypeKeys.CLEARSTATE });
				history.replace(error.path || '/');
				return;
			}
			if (error.path !== '/') {
				history.replace(error.path);
				return;
			}
		}

		const errorComponent = getErrorComponents(error, { ...defaultErrorComponents, ...errorComponents }, { notFountMessage });
		if (errorComponent) {
			setErrorComponent(errorComponent);
		} else {
			errorHandlerForCustomCodes && errorHandlerForCustomCodes(error);
		}

		console.log(error.message);
	};

	const request = React.useMemo(() => {
		return <T, >(type: string, params: BaseParams = {}, beforeRequest, ttl = defaultTtl, signal?: AbortSignal): Promise<T> => {
			if (cacheProps && ttl && ttl > 0) {
				const key = `${type}${JSON.stringify(params)}`;

				if (cache[key]) {
					return Promise.resolve(cache[key]);
				}
			}
			beforeRequest && beforeRequest();

			return fetch('api/post', {
				credentials: 'same-origin',
				method: 'POST',
				headers: {
					'Content-type': 'application/json; charset=utf-8',
					Cookie: `session=${session || ''}`,
				},
				body: JSON.stringify({
					type,
					data: JSON.stringify(params),
				}),
				signal,
			})
				.then((response) => response.json() as Message<T | ResponseError>)
				.then((data: Message<T | ResponseError>) => {
					if (!data.success) {
						throw data.response as ResponseError;
					}

					updateCache(type, params, data.response, ttl);

					return data.response as T;
				})
				.catch((error: ResponseError) => {
					errorHandler(error);

					throw error.message as string;
				});
		};
	}, [session, getErrorComponents, history.location, cacheProps, cache]);

	React.useEffect(() => {
		return history.listen((location, action) => {
			if (errorComponent) {
				setErrorComponent(null);
			}
		});
	}, [errorComponent]);

	const value = {
		state: {
			request,
			notFountMessage,
		},
		actions: {
			updateCache,
			getFromCache,
		},
	};

	return (
		<ItemContext.Provider value={value}>
			{errorComponent || children}
		</ItemContext.Provider>
	);
};

import { phoneFormat } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';

import { truncateSeconds } from '@app/utils/utils';
import { getWorkingDays } from '@app/utils/getWorkingDays';
import { ClearedLocation, Location } from '@app/objects/Location';

export const getClearedLocation = (location: Location): ClearedLocation => {
	return {
		phone: phoneFormat(location.phone),
		address: `${location.addressEn}, ${location.city}, ${location.state}, ${location.zip}`,
		workingHours: `${truncateSeconds(location.startTime)} - ${truncateSeconds(location.endTime)}`,
		workingDays: getWorkingDays(location.workingHours),
		siteAvatar: location?.siteAvatar,
	};
};

import * as React from 'react';

import { useHistory } from 'react-router-dom';

import { useRequestProviderContext } from '@common/react/components/RequestProvider/RequestProvider';

const PageNotFound: React.FC<{ message?: string}> = ({ message }) => {
	const history = useHistory<{ prevPath?: string }>();
	const context = useRequestProviderContext();
	const notFountMessage = message || context?.state?.notFountMessage || '404 Not Found';

	const handlerBack = () => {
		const { location } = history;
		if (location.state?.prevPath) {
			history.push(location.state.prevPath);
		} else {
			history.push('/');
		}
	};
	const havePrevPage = history.location.state?.prevPath;

	return <div className="page-not-found" style={{ flexDirection: 'column' }}>
		<h1
			className="page-not-found__header"
			style={{
				margin: 'auto auto 25px',
				padding: '0 30px',
				textAlign: 'center',
				lineHeight: 1.4,
			}}
		>
			{notFountMessage}
		</h1>
		<div className="text-center" style={{ margin: '25px auto auto' }}>
			<button type="button" className="btn btn-default" onClick={() => handlerBack()}>
				Go&nbsp;
				{havePrevPage ? 'Back' : 'to Main page'}
			</button>
		</div>
	</div>;
};

export default PageNotFound;

import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import MainLayout from '@app/components/Layouts/MainLayout';

interface Props extends RouteProps {
	component: any;
}

const MainRoute: React.FC<Props> = ({
	component: Component,
	children,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				<MainLayout>
					<Component {...props} />
				</MainLayout>
			)}
		/>
	);
};

export default MainRoute;

import { addTask } from 'domain-task';
import { Reducer } from 'redux';

import { request } from '@common/react/components/Api';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState, BaseAppThunkAction } from '@common/react/store/index';
import { Lang } from '@common/typescript/objects/Lang';

export interface LoginState<TUser extends BaseUser> {
	isLoading: boolean;
	session: string;
	user: TUser | null;
	message: string;
	transmuted: boolean;
	debug: boolean;
	lang: Lang;
	userAgent: string;
}

export enum TypeKeys {
	REQUESTLOGIN = 'REQUEST_LOGIN',
	RECEIVELOGIN = 'RECEIVE_LOGIN',
	REQUESTLOGOFF = 'REQUEST_LOGOFF',
	RECEIVELOGOFF = 'RECEIVE_LOGOFF',
	SETSESSION = 'SET_SESSION',
	UPDATEUSER = 'UPDATE_USER',
	CLEARSTATE = 'CLEAR_STATE',
	SETLANG = 'SET_LANG',
}

interface RequestLoginAction {
	type: TypeKeys.REQUESTLOGIN;
}

interface ReceiveLoginAction {
	type: TypeKeys.RECEIVELOGIN;
	user: BaseUser | null;
	session: string;
	message: string;
	transmuted: boolean;
	debug: boolean;
	lang: Lang;
	userAgent: string;
}

interface RequestLogoffAction {
	type: TypeKeys.REQUESTLOGOFF;
}

interface ReceiveLogoffAction {
	type: TypeKeys.RECEIVELOGOFF;
	session: string;
}

interface SetSessionAction {
	type: TypeKeys.SETSESSION;
	session: string;
}

interface SetLangAction {
	type: TypeKeys.SETLANG;
	lang: Lang;
}

interface UpdateUserAction {
	type: TypeKeys.UPDATEUSER;
	data: any;
	setUser?: (user) => any;
}

interface ClearStateAction {
	type: TypeKeys.CLEARSTATE;
}

type KnownUserAction =
	RequestLoginAction |
	ReceiveLoginAction |
	RequestLogoffAction |
	ReceiveLogoffAction |
	SetSessionAction |
	UpdateUserAction |
	ClearStateAction |
	SetLangAction;

export interface LoginActionCreators<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	login: (login: string, password: string, path?: string) => BaseAppThunkAction<KnownUserAction, TUser, TApplicationState>;
	logoff: (clearState?: boolean, callback?: () => void) => BaseAppThunkAction<KnownUserAction, TUser, TApplicationState>;
	updateUser: (data: any, setUser?: (user) => any) => BaseAppThunkAction<KnownUserAction, TUser, TApplicationState>;
	setUserAndSession: (user: BaseUser, session: string) => BaseAppThunkAction<KnownUserAction, TUser, TApplicationState>;
	setLang: (lang: Lang) => BaseAppThunkAction<KnownUserAction, TUser, TApplicationState>;
}

export function getActionCreators<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>() {
	return {
		login: (login: string, password: string, path: string = 'auth'): BaseAppThunkAction<KnownUserAction, TUser, TApplicationState> =>
			(dispatch, getState) => {
				if (!getState().login.isLoading) {
					const fetchTask = request<any, TUser, TApplicationState>(path, {
						login,
						password,
						path: '/login',
					}).then((data) => {
						if (data.initObject) {
							dispatch({
								type: TypeKeys.RECEIVELOGIN,
								user: data.initObject.user,
								session: data.initObject.guid,
								message: '',
								transmuted: data.initObject.transmuted,
								debug: data.initObject.debug,
								lang: data.initObject.lang,
								userAgent: data.initObject.userAgent,
							});
						}
					}).catch((data) => {
						dispatch({
							type: TypeKeys.RECEIVELOGIN,
							user: null,
							session: getState().login.session,
							message: data,
							transmuted: false,
							debug: false,
							lang: Lang.En,
							userAgent: '',
						});
					});

					addTask(fetchTask);
					dispatch({ type: TypeKeys.REQUESTLOGIN });
				}
			},
		logoff: (
			clearState?: boolean,
			callback?: () => void,
		): BaseAppThunkAction<KnownUserAction, TUser, TApplicationState> => (dispatch, getState) => {
			if (!getState().login.isLoading) {
				const fetchTask = request<any, TUser, TApplicationState>('logoff', {}).then((data) => {
					if (data.newSessionGuid) {
						dispatch({ type: TypeKeys.RECEIVELOGOFF, session: data.newSessionGuid });
					}

					if (callback) callback();

					if (clearState) {
						dispatch({ type: TypeKeys.CLEARSTATE });
					}
				});

				addTask(fetchTask);

				dispatch({ type: TypeKeys.REQUESTLOGOFF });
			}
		},
		updateUser: (data: any, getUser?: (user) => any): BaseAppThunkAction<KnownUserAction, TUser, TApplicationState> => (dispatch, getState) => {
			const partialUser = getUser && getUser(getState().login?.user);
			dispatch({ type: TypeKeys.UPDATEUSER, data: { ...partialUser, ...data } });
		},
		setUserAndSession: (user: BaseUser, session: string):
			BaseAppThunkAction<KnownUserAction, TUser, TApplicationState> => (dispatch, getState) => {
			const state = getState().login;
			dispatch({
				type: TypeKeys.RECEIVELOGIN,
				user,
				session,
				message: '',
				transmuted: false,
				debug: state.debug || false,
				lang: state.lang,
				userAgent: state.userAgent,
			});
		},
		setLang: (lang: Lang): BaseAppThunkAction<KnownUserAction, TUser, TApplicationState> => (dispatch, getState) => {
			request<any, TUser, TApplicationState>('language', { lang }).then((data) => {
				dispatch({ type: TypeKeys.SETLANG, lang });
			});
		},
	};
}

export function getReducer<TUser extends BaseUser>(): Reducer<LoginState<TUser>> {
	return (s: LoginState<TUser> | undefined, action: KnownUserAction) => {
		const state = s as LoginState<TUser>;
		switch (action.type) {
			case TypeKeys.REQUESTLOGIN:
				return { ...state, isLoading: true };
			case TypeKeys.RECEIVELOGIN:
				return {
					...state,
					isLoading: false,
					user: action.user,
					session: action.session,
					message: action.message,
					transmuted: action.transmuted,
					debug: action.debug,
					lang: action.lang,
					userAgent: action.userAgent,
				};
			case TypeKeys.REQUESTLOGOFF:
				return { ...state, isLoading: true };
			case TypeKeys.RECEIVELOGOFF:
				return {
					...state, isLoading: false, user: null, session: action.session, transmuted: false,
				};
			case TypeKeys.SETSESSION:
				return { ...state, session: action.session };
			case TypeKeys.SETLANG:
				return { ...state, lang: action.lang };
			case TypeKeys.CLEARSTATE:
				return {
					...state, user: null, isLoading: false, message: '', session: '', transmuted: false,
				};
			case TypeKeys.UPDATEUSER:
				return {
					...state,
					user: {
						...(state.user as any),
						...action.data,
					},
				};
			default:
				const exhaustiveCheck: never = action;
		}

		return state || { user: null };
	};
}

import * as React from 'react';

import * as Yup from 'yup';
import Input from 'antd/lib/input';
import Message from 'antd/lib/message';
import { Formik, FormikProps, FormikHelpers } from 'formik';

import Button from '@common/react/components/Forms/Button';
import { emailValidator, phoneRequiredValidator } from '@common/react/utils/validationHelpers';
import { FormikPhoneControl } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';

import { request } from '@app/components/Api';
import { stringValidator } from '@app/utils';

interface FormValues {
	name: string;
	phone: string;
	email: string;
	text: string;
}

const validationSchema = Yup.object().shape({
	name: stringValidator,
	phone: phoneRequiredValidator,
	email: emailValidator,
	text: stringValidator,
});

const BookAppointmentForm: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string>('');

	const onSubmit = (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
		setIsLoading(true);
		setError('');

		request<FormValues>('sendInquiry', values)
			.then(() => {
				setIsLoading(false);
				Message.success('Your appointment has been successfully sent.');
				formikHelpers.resetForm();
			})
			.catch((err) => {
				setIsLoading(false);

				if (typeof err === 'string') {
					setError(err);
				} else {
					setError('Something went wrong.');
				}
			});
	};

	return <Formik<FormValues>
		initialValues={{
			name: '',
			phone: '',
			email: '',
			text: '',
		}}
		onSubmit={onSubmit}
		validationSchema={validationSchema}
	>
		{(formikProps: FormikProps<FormValues>) => <div className="book-apt-form">
			<h3 className="book-apt-form__text">
				Experience the difference at Northern Tuna Sample Clinic.
				Book your appointment today and get ready to look and feel your very best.
			</h3>
			<div className="row">
				<FormikField
					fieldName="name"
					containerClassName="form-group col-sm-12 name"
					inputProps={{ placeholder: 'Name' }}
				/>
			</div>
			<div className="row">
				<FormikField
					fieldName="phone"
					containerClassName="form-group col-sm-12 phone"
					render={(fieldProps) =>
						<FormikPhoneControl
							fieldProps={fieldProps}
							placeholder="+1 (___) ___-____"
						/>
					}
				/>
			</div>
			<div className="row">
				<FormikField
					fieldName="email"
					containerClassName="form-group col-sm-12 email"
					inputProps={{ placeholder: 'E-mail' }}
				/>
			</div>
			<div className="row">
				<FormikField
					fieldName="text"
					containerClassName="form-group col-sm-12 message"
					render={({ field }) =>
						<Input.TextArea
							className="form-control"
							style={{ resize: 'none' }}
							autoSize={{ minRows: 6, maxRows: 6 }}
							placeholder="Message"
							{...field}
						/>
					}
				/>
			</div>
			<div className="text-center">
				<Button
					type="button"
					className="btn"
					isLoading={isLoading}
					onClick={() => formikProps.handleSubmit()}
				>
					Submit
				</Button>
			</div>
			{error && <div className="alert alert-danger mt10">{error}</div>}
		</div>}
	</Formik>;
};

export default BookAppointmentForm;

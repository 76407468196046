import { ReducersMapObject } from 'redux';

import * as Item from '@common/react/store/Item';
import { BaseApplicationState, BaseAppThunkAction, baseReducers } from '@common/react/store';
import { PageItemState, reducer as PageStateReducer } from '@common/react/store/PageItem';

import { SiteViewSettings } from '@app/objects/SiteViewSettings';
import { User } from '@app/objects/User';
import { Location } from '@app/objects/Location';

// The top-level state object
export interface ApplicationState extends BaseApplicationState<User> {
	siteViewSettings: Item.ItemState<SiteViewSettings>;
	location: Item.ItemState<Location>;
	serverPage: PageItemState<any>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers,
	siteViewSettings: Item.getReducer<SiteViewSettings>('siteViewSettings'),
	location: Item.getReducer<Location>('location'),
	serverPage: PageStateReducer<any>('serverPage'),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = BaseAppThunkAction<TAction, User, ApplicationState>
